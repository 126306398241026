import React from 'react'
import { 
	graphql, 
	// Link 
} from "gatsby"

// Layout
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Matrix from "../../components/Matrix"
import { buildFluidImageData } from '@imgix/gatsby'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Tailwind React UI Components
import { 
	Box,
	Container,
	Flex,
	Section,
	Text
} from 'tailwind-react-ui'

import Heading from '../../components/Matrix/types/BodyContent/Heading'
import ImgixFluidImage from "../../components/ImgixFluidImage"

// Custom Components
import Accordion from '../../components/Matrix/types/BodyContent/Accordion'
import Download from '../../components/Matrix/types/BodyContent/Download'
import PageBanner from '../../components/PageBanner'

// Hard-coded image imports
import BlockBlue from '../../images/patterns/pattern-block_blue.svg'
import Frame03 from '../../images/patterns/pattern-frame03.svg'

// GraphQL
export const query = graphql`
	query HealthAndSafetyQuery {
		entry: craftHealthAndSafetyHealthAndSafetyEntry {
			id
			remoteId
			subtitle
			title
			typeHandle
			uri
			url
			featureImage { ...UploadAssetFragment }
			displayTitle
			summary
			metaTitle
			metaDescription
			metaKeywords
			metaImage { ...UploadAssetFragment }
			heading {
				... on Craft_heading_heading_BlockType {
				  id
				  text
				}
			}
			richText
			bodyContent {
				... BodyContentFragment
			}
			image {
				... on Craft_image_image_BlockType {
				  id
				  image { ...UploadAssetFragment }
				}
			  }
			download {
				... on Craft_download_download_BlockType {
					heading
					file {
						title
						size
						url
					}
				}
			}
			accordion { 
				... on Craft_accordion_accordionRow_BlockType {
					id
					heading
					text
				}
			}
		}
		siteInfo: craftSiteInformationGlobalSet {
			...SiteInfoFragment
		}
	}
`

const truncateString = (string = '', maxLength = 54) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const HealthAndSafety = ({ data: { entry, siteInfo } }) => {
	const subtitle = entry.subtitle || siteInfo.siteTitle
	const [ siteImage ]  = siteInfo.siteImage
	const [ featureImage = siteImage ] = entry.featureImage
	const metaImage = featureImage || entry.metaImage || siteInfo.siteImage
	const metaDescription =  truncateString((entry.metaDescription || entry.summary || siteInfo.siteDescription).replace(/(<([^>]+)>)/gi, ""), 159)
	const metaTitle = truncateString(`${entry.metaTitle || entry.displayTitle || entry.title}`, 53)
	const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300
		}
    )

	return (
		<Layout location={entry.url} title={metaTitle}>
			<Seo
				title       = {metaTitle}
				description = {metaDescription}
				keywords    = {metaKeywords}
				image       = {metaImageFormatted.src}
				url         = {entry.url}
				pathname	= {`/${entry.uri}`}
			/>
			<h1 style={{fontSize: 0, color: 'transparent'}} className="sr-only">{metaTitle}</h1>

			<Section p="0" className="relative">
				<PageBanner
					title    = {entry.title}
					subtitle = {subtitle}
					image    = {featureImage}
				/>
			</Section>

			<Section p="0">
				<Container className="xl:w-3/4 pt-20 pb-3">
					<Box className="mx-auto">
						<Heading
							block = {{
								hierarchy: 2,
								align    : "center",
								text     : entry.displayTitle
							}}
						/>
						<Text className="text-center text-md" leading="snug" dangerouslySetInnerHTML={{__html: entry.summary}} />
					</Box>
				</Container>
			</Section>
			
			<Section p="0">
				<Container className="pb-20">
					<Flex wrap align="start" justify="between" className="lg:w-5/6 mx-auto">
						{entry.image[0].image[0] ? <Box className="w-2/3 lg:w-2/5 px-5 lg:px-3 py-4 mx-auto lg:ml-0">
						<div className="relative w-full">
							<BlockBlue className="absolute -top-5 -left-5" alt="Frame" />
							<Frame03 className="z-20 absolute -top-3 -left-9 w-full" alt="Frame" />
							<ImgixFluidImage className="relative block shadow-md rounded-lg w-100 bg-gray-light" alt={entry.title} imageUrl={entry.image[0].image[0] ? entry.image[0].image[0].url : siteInfo.siteImage[0].url} ar={1/1} />
						</div>
						</Box> : ''}
						<Box className="w-full xl:w-3/5 p-4 overflow-hidden">
							{entry.heading[0] ? 
								<Heading
									block = {{
										hierarchy: 3,
										text : entry.heading[0].text
									}}
									margins = "my-0"
								/>
							: ''}
							<Text text="primary" dangerouslySetInnerHTML={{__html: entry.richText}} />
							{entry.download.map((file, i) => (
								<Download 
									key     = {i}
									classes = "text-sm text-bold" block = {{
									file   : file.file,
									heading: file.heading ?? file.file[0].title,
									size   : file.file[0].size
								}} />
							))}
						</Box>
					</Flex>
				</Container>
			</Section>

			<Section bg="blue-dark" className="relative" p="0">
				<Container className="xl:w-3/4 pt-20 pb-24">
					<Heading
						block = {{
							hierarchy: 2,
							align    : "center",
							text     : "Health & Safety FAQs",
							colour   : "white"
						}}
						margins = "mb-14"
					/>
					{entry.accordion.map((block, i) => (
						<Accordion 
							key   = {`acc${i}`}
							arrow = "#e5e7eb"
							theme = "white"
							block = {block} />
					))}
				</Container>
			</Section>

			{entry.bodyContent.length ? <Section p="0" className="secondary-page relative">
				<Container className="xl:w-4/5 pt-14 pb-8">
					<Matrix blocks={entry.bodyContent} />
				</Container>
			</Section>: '' }
			
		</Layout>
	)
}

export default HealthAndSafety